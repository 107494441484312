import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Banner } from './components/Banner'
import { Product } from './components/Product'
import { NewsCenter } from './components/NewsCenter'
import { CliqueInfo } from './components/CliqueInfo'
import { Element, scroller } from 'react-scroll'
import { throttle } from '@/utils/tool'

export const Home = () => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  useEffect(() => {
    const handleScroll = (e: { deltaY: any }) => {
      const delta = e.deltaY
      if (delta > 0) {
        // 向下滚动，执行切换到下一页的逻辑
        scrollNextPage()
      } else if (delta < 0) {
        // 向上滚动，执行切换到上一页的逻辑
        scrollPrevPage()
      }
    }

    const scrollNextPage = () => {
      // 执行切换到下一页的逻辑
      if (currentPageIndex <= 2) {
        const nextPage = currentPageIndex + 1
        scroller.scrollTo(`page${nextPage}`, {
          duration: 800,
          delay: 0,
          smooth: true
        })
        throttle(() => setCurrentPageIndex(nextPage), 800, false)()
      }
    }

    const scrollPrevPage = () => {
      // 执行切换到上一页的逻辑
      if (currentPageIndex > 0) {
        const prevPage = currentPageIndex - 1
        scroller.scrollTo(`page${prevPage}`, {
          duration: 800,
          delay: 0,
          smooth: true
        })
        throttle(() => setCurrentPageIndex(prevPage), 800, false)()
      }
    }

    window.addEventListener('wheel', handleScroll)
    return () => {
      window.removeEventListener('wheel', handleScroll)
    }
  }, [currentPageIndex])

  useEffect(() => {
    scroller.scrollTo('page0', {
      duration: 1000,
      delay: 100,
      smooth: true
    })
  }, [])

  return (
    <Wrapper>
      <Element name="page0">
        <Banner/>
      </Element>
      <Element name="page1">
        <Product />
      </Element>
      <Element name="page2">
        <NewsCenter />
      </Element>
      <Element name="page3">
        <CliqueInfo />
      </Element>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0;
`
