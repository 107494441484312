import * as R from 'ramda'
import React from 'react'
import { Route } from 'react-router-dom'
import { MenuInfo } from '@/consts/menu'

const toMenuRoute = ({ path, page }: MenuInfo) =>
  page ? <Route key={path} path={path} element={page} /> : <Route key={path} path={path} element='' />

export const getMenuRoutes = (menus?: MenuInfo[]): JSX.Element[] => {
  const nestedRoutes =
    menus?.map((menu) => [
      toMenuRoute(menu),
      ...getMenuRoutes(menu.children),
    ]) ?? []

  return R.flatten(nestedRoutes).filter((v): v is JSX.Element => !!v)
}
