/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import NewsBg from '@/assets/home/home_news_bg.jpg'
import VideoBg from '@/assets/home/video_icon.jpg'
import PlayIcon from '@/assets/home/play.png'
import { getList } from '../api/news'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

interface NewItem {
  content:string
  createTime:string
  first:number
  id:number
  image:string
  title:string
}

export const NewsCenter = () => {
  const [playOn, setPlayOn] = useState(false)
  const [showAnimate, setShowAnimate] = useState<boolean>(false)

  const playVideo = () => {
    setPlayOn(!playOn)
    if (!playOn) return
    videoPlay()
  }

  const [list, setList] = useState<NewItem[]>([])

  const queryList = useCallback(async () => {
    try {
      const { data } = await getList({ 'typeId': 2, 'pageNum': 1, 'pageSize': 6 })
      setList(data.data || [])
    } catch (err) {
      console.log('获取列表失败', err)
    }
  }, [])

  useEffect(() => {
    queryList()
  }, [])

  const navigate = useNavigate()
  const toDetail = (id: number) => {
    navigate(`/news/detail?id=${id}`)
  }

  useEffect(() => {
    const handleScroll = () => {
      // 获取元素相对于窗口的位置
      const element = document.getElementById('pcNews')
      if (!element) {
        return
      }
      const elementRect = element.getBoundingClientRect()
      const elementTop = elementRect.top + window.scrollY
      const elementBottom = elementRect.bottom + window.scrollY

      // 判断元素是否需要添加动效
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const windowHeight = window.innerHeight
      if (elementTop < scrollTop + windowHeight && elementBottom > scrollTop) {
        setShowAnimate(true)
      } else {
        setShowAnimate(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])


  return (
    <Wrapper>
      <div className='w-full sm:hidden pc h-screen pt-28' id='pcNews'>
        <div className="title text-center">新闻中心</div>
        <div className={`news flex ${showAnimate ? 'flowIn' : ''}`}>
          <div className={`news-info grid grid-rows-3 gap-3 gap-y-3 flex-1 mr-3 ${list.length > 3 ? 'grid-cols-2' : 'grid-cols-1'}`}>
            {
              list.map((item, index) => <div className='news-info-item p-6 cursor-pointer' key={index} onClick={() => toDetail(item.id)}>
                <div className="news-info-item-title text-base line-clamp-2 font-medium">{item.title}</div>
                <div className='news-info-item-time font-normal mt-5'>{moment(item.createTime).format('YYYY-MM-DD')}</div>
              </div>)
            }
          </div>
          <div className={`video flex items-center cursor-pointer relative flex-1 ${playOn ? 'on' : 'off'}`}>
            <img src={VideoBg} onClick={playVideo} alt="宝湾资本" />
            <div className='video-play w-full absolute' onClick={playVideo}>
              <img src={PlayIcon} alt="宝湾资本" />
            </div>
            <video loop muted controls src='https://bwzb-prod.oss-cn-shenzhen.aliyuncs.com/video/videoPPT.mp4'>
            </video>
          </div>
        </div>
      </div>
      <div className='w-full md:hidden lg:hidden mobile p-4'>
        <div className="title text-center mt-8">新闻中心</div>
        <video className='video mt-5' loop muted controls src='https://bwzb-prod.oss-cn-shenzhen.aliyuncs.com/video/videoPPT.mp4'>
        </video>
        <div className='news'>
          {
            list.map((item, index) => <div className='news-item p-6 mt-3' onClick={() => toDetail(item.id)} key={index}>
              <div className="news-item-title text-base line-clamp-2 font-medium">{item.title}</div>
              <div className='news-item-time font-normal mt-5'>{moment(item.createTime).format('YYYY-MM-DD')}</div>
            </div>)
          }
        </div>

      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`

@keyframes slideInUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.pc{
    background: url(${NewsBg});
    background-size: 100% 100%;
    .title{
        font-size: 40px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #040814;
    }
    .flowIn{
      animation: slideInUp 2s forwards;
    }
    .news{
        width: 1200px;
        margin: 96px auto 0;
       .news-info{
        .news-info-item{
        height: 132px;  
        background: #FFFFFF;
        font-family: PingFang SC;
        .news-info-item-title{     
            color: #43464F;
        }
        .news-info-item-time{    
        color: #8E9095;
        }
        }
        .news-info-item:hover{
          background: #0169CB;
          .news-info-item-title,.news-info-item-time{
            color: #fff !important;
          }
        }
       }
       .video{
            width: 560px;
            height:420px;
            background: #040814;
            img{
              width: 100%;
              height: 100%;
            }
            video{
              object-fit:contain
            }
       }
       .on{
         img{
          display: none !important;
         }
         .video-play{
          display: none !important;
         }
       }
       .off{
        img{
          display: block !important;
         }
         video{
          display: none !important;
         }
         .video-play{
          text-align: center;
          display: block !important;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          img{
            margin: 0 auto;
            display: inline-block;
            width: 42px;
            height: 42px;
          }
         }
       }
    }
}

.mobile{
    background: #F4F4F7;
    .title{
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #040814;
    }
    .video{
            width: 100%;
            height: 257px;
       }
       .news-item{
        height: 132px;
        background: #FFFFFF;
        .news-item-title{
            color: #43464F;
        }
        .news-item-time{
            color: #8E9095;
        }
       }
}
 
`
function videoPlay() {
  throw new Error('Function not implemented.')
}

