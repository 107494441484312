import React from 'react'
import styled from 'styled-components'
import Phone from '@/assets/about/phone.png'
import Address from '@/assets/about/address.png'
import MapComponent from './MapComponent'
import { isMobile } from 'react-device-detect'

const info = [
  {
    icon: Phone,
    name: '联系电话：',
    text: '0755-21622822'
  },
  {
    icon: Address,
    name: '公司地址：',
    text: '深圳市南山区赤湾六路8号赤湾总部大厦28楼'
  }
]

export const Contact = () => {


  return (
    <Wrapper>
      <div className='sm:hidden pc pt-44'>
        <div className="title text-center">联系我们</div>
        <div className="info flex justify-between mt-16">
          {
            info.map((item, index) =>
              <div key={index} className="info-item">
                <div>
                  <img src={item.icon} alt="宝湾资本" />
                </div>
                <div className='name mt-4 text-base'>{item.name}</div>
                <div className='mt-2 text-base'>{item.text}</div>
              </div>
            )
          }
        </div>

      </div>

      <div className='md:hidden lg:hidden mobile w-full pt-12'>
        <div className="title text-xl pl-4 text-center">联系我们</div>
        <div className="info w-full flex justify-between mt-6">
          {
            info.map((item, index) =>
              <div key={index} className="info-item">
                <div>
                  <img src={item.icon} alt="宝湾资本" />
                </div>
                <div className='name mt-4 text-base'>{item.name}</div>
                <div className='mt-2 text-base'>{item.text}</div>
              </div>
            )
          }
        </div>
      </div>
      <div className={`map ${isMobile ? 'pt-8' : 'mt-14'}`} style={{ height: `${isMobile ? '312px' : '480px'}`, background: `${isMobile ? '#F4F4F7' : 'none'}` }}>
        <MapComponent mapId="pcMap" height={`${isMobile ? '280px' : '480px'}`} />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
.pc{
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #040814;
    font-family: PingFang SC;
    .info{
        width: 1200px;
        margin: 0 auto;
        .info-item{
        width: 588px;
        text-align: center;
        color: #43464F;
        img{
            display: inline-block;
            width: 48px;
            height: 48px;
        }
        .name{   
            color: #040814; 
        }
    } 
    }
    
}

.mobile{
    background: #F4F4F7;
    .title{
        font-family: PingFang SC;
        font-weight: 600;
        color: #040814;
    }
    .info{
        padding: 0 16px;
        .info-item{
        width: 164px;
        text-align: center;
        color: #43464F;
        img{
            display: inline-block;
            width: 48px;
            height: 48px;
        }
        .name{   
            color: #040814; 
        }
    } 
    }
}

`
