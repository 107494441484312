import React from 'react'
import next_icon from '@/assets/next_grey.png'
import { useNavigate } from 'react-router-dom'

interface BreadcrumbProps {
  list: {
    title: string,
    path?: string
  }[]
}

export const Breadcrumbs = ({ list }: BreadcrumbProps) => {
  const navigate = useNavigate()
  const onNavigate = (path?: string) => {
    path && navigate(path)
  }
  return <div className=' bg-borderTwo'>
    <div className='sm:w-full sm:px-4 md:w-11/12 lg:w-[1200px] mx-auto flex'>
      {
        list.map((item, index) => <div key={item.title} className='flex items-center text-textTertiary last:text-textSecondary text-base py-4 sm:text-sm sm:py-3 cursor-pointer' onClick={() => { onNavigate(item.path) }}>
          <span>{item.title}</span>
          {index < list.length - 1 && <img className=' w-4 h-4 mx-1' src={next_icon} />}
        </div>)
      }
    </div>
  </div>
}
