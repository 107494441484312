import React, { useEffect, useState } from 'react'
import logo from '@/assets/logo.png'
import logo_white from '@/assets/logo_white.png'
import nav from '@/assets/nav.png'
import nav_white from '@/assets/nav_white.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { Nav } from './Nav'
import { isValidHttpUrl } from '@/utils/tool'
import { HeaderMenu } from '@/consts/menu'

export const Header = () => {
  const menuList = [...HeaderMenu]
  const location = useLocation()
  const ifHome = location.pathname === '/'

  const [visible, setVisible] = useState(false)

  const navigate = useNavigate()
  const onNavigate = (url: string) => {
    if (!url) return
    if (isValidHttpUrl(url)) {
      window.open(url)
    } else {
      navigate(url)
    }
  }

  const [isFixed, setIsFixed] = useState(false)
  useEffect(() => {
    if (ifHome) {
      window.addEventListener('scroll', () => {
        const bannnerElement = document.getElementById('homeBanner')
        const headerHeight = document.getElementById('pcHeader')?.offsetHeight || 0
        if (bannnerElement) {
          const bannnerTop = bannnerElement.offsetTop
          const bannerHeight = bannnerElement?.offsetHeight || 0
          const scrollTop = document.documentElement.scrollTop
          const isFixed = scrollTop >= (bannnerTop + bannerHeight - headerHeight)
          setIsFixed(isFixed)
        }
      })
    }
    if (location.pathname === '/business') {
      touchMove()
    }
  }, [location.key])

  // 判断页面下滑还是上滚
  const [ifHigher, setIfHigher] = useState(false)
  const touchMove = () => {
    const body = document.body
    let startY = 0
    body.addEventListener('touchstart', (e) => {
      startY = e.changedTouches[0].pageY
    })
    body.addEventListener('touchmove', (e) => {
      const moveEndY = e.changedTouches[0].pageY
      const y = moveEndY - startY
      setIfHigher(y > 0)
    })
  }

  const bgWhite = !ifHome || isFixed
  return <div>
    {/* 大屏 */}
    <div className='sm:hidden'>
      <div id="pcHeader" className={`group sm:py-3 z-30 ${ifHome ? `${isFixed && 'bg-white'} transition-all ease-linear duration-300 hover:bg-white fixed top-0 left-0 right-0` : 'relative bg-white'}`}>
        <header className='md:w-11/12 lg:w-[1200px] mx-auto flex justify-between items-center'>
          <div>
            <img className={`w-[263px] h-12 cursor-pointer group-hover:hidden ${bgWhite && 'hidden'}`} onClick={() => { onNavigate('/') }} src={logo_white} />
            <img className={`${!bgWhite && 'hidden'} group-hover:block w-[263px] h-12 cursor-pointer`} onClick={() => { onNavigate('/') }} src={logo} />
          </div>
          <ul className='flex items-center text-base '>
            {
              menuList.map(item =>
                <li key={item.title}
                  className="relative group/item font-medium w-[128px] cursor-pointer lg:h-[72px] md:h-[72px] flex items-center hover:after:h-full after:content after:block after:absolute after:bg-primary after:w-full after:h-0 after:left-0 after:bottom-0 after:z-[-1] after:transition-all after:ease-linear after:duration-300">
                  <div
                    onClick={() => { onNavigate(item.path) }}
                    className={`mx-auto text-center group-hover/item:!text-white group-hover/item:!border-white ${item.title === '业务平台' ? `${bgWhite ? 'text-primary border-primary' : 'text-white border-white'} w-[88px] group-hover:text-primary group-hover:border-primary border rounded-2xl` : `${bgWhite ? 'text-textPrimary' : 'text-white'} group-hover:text-textPrimary`}
                    `}>{item.title}</div>
                  {
                    item.children?.length && <ul className='group-hover/item:opacity-100 group-hover/item:visible group-hover/item:animate-navDown absolute top-[72px] left-0 right-0 z-20 transition-all ease-linear duration-300 opacity-0 invisible bg-white shadow-md'>{
                      item.children.map(subItem =>
                        <li className='py-4 text-textSecondary font-normal text-center hover:bg-primary hover:text-white' key={subItem.title} onClick={() => { onNavigate(subItem.path) }}>{subItem.title}</li>)
                    }</ul>
                  }
                </li>
              )
            }
          </ul>
        </header>
      </div>
      {/* <div className={ifHome ? 'hidden' : 'h-[72px]'}></div> */}
    </div >
    {/* 小屏 */}
    <div className='lg:hidden md:hidden'>
      <div className={`${!bgWhite ? '' : ' bg-white'} z-10 px-4 py-3 flex justify-between fixed left-0 right-0 ${ifHigher && 'z-20'}`}>
        <img className='w-[132px] h-6' onClick={() => { onNavigate('/') }} src={!bgWhite ? logo_white : logo}></img>
        <img className='w-6 h-6' onClick={() => { setVisible(true) }} src={!bgWhite ? nav_white : nav}></img>
      </div>
      <div className={ifHome ? 'hidden' : 'h-12'}></div>
      <Nav visible={visible} onChange={(val) => { setVisible(val) }}></Nav>
    </div >
  </div>
}
