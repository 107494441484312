/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum Color {

  PrimaryBG = '#0169CB', // 主色背景
  DeriveBG = '#4397D5', // 主色衍生背景色
  SubsidiaryBG = '#BE985F', // 辅助色
  WhiteBG = '#FFFFFF', // 白背景

  // 文字颜色
  TextPrimary = '#040814', // 强调文字/正文标题
  TextSecondary = '#43464F', // 正文文字/文字悬浮
  TextTertiary = '#8E9095', // 辅助文字
  TextWhite = '#FFFFFF', // 纯白文字

  // 边框颜色
  BorderLineOne = '#F2F2F5',
  BorderLineTow = '#F4F4F7'
}
