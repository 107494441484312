import Request from '@/utils/request'

interface Props{
    pageNum:number
    pageSize:number
    typeId:number
}

/**
 * 获取首页新闻
 */
export const getList = (params:Props) => {
  return Request.post('/front/news/findAllSelectedArticles', params)
}
