/* eslint-disable react/jsx-key */
import React from 'react'
import styled from 'styled-components'
import Tteam_building1 from '@/assets/about/team_building1.jpg'
import Tteam_building2 from '@/assets/about/team_building2.jpg'
import Tteam_building3 from '@/assets/about/team_building3.jpg'
import Tteam_building4 from '@/assets/about/team_building4.jpg'
import Tteam_building5 from '@/assets/about/team_building5.jpg'
import Tteam_building6 from '@/assets/about/team_building6.jpg'
import Tteam_building7 from '@/assets/about/team_building7.jpg'
import Tteam_building8 from '@/assets/about/team_building8.jpg'


const list = [
  Tteam_building1, Tteam_building2, Tteam_building3, Tteam_building4,
  Tteam_building5, Tteam_building6, Tteam_building7, Tteam_building8,
]

export const Team = () => {

  return (
    <Wrapper>
      <div className='sm:hidden pc pt-44'>
        <div className="title text-xl pl-4">团队建设</div>
        <div className="photo mt-12 flex flex-wrap justify-between w-full">
          {
            list.map((item, index) => <div className='overflow-hidden' key={index}><img className='object-cover hover:scale-105 transition-all duration-500 ease-in' src={item} alt="宝湾资本" /></div>)
          }
        </div>
      </div>
      <div className='md:hidden lg:hidden mobile w-full pt-12'>
        <div className="title text-xl pl-4">团队建设</div>
        <div className="photo mt-6">
          {
            list.map((item, index) => <div className='overflow-hidden' key={index}><img className='object-cover hover:scale-105 transition-all duration-500 ease-in' src={item} alt="宝湾资本" /></div>)
          }
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
.pc{
    .title{
        width: 1200px;
        margin: 0 auto;
        font-size: 40px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #040814;
    }
    .photo{
        div:nth-child(-n+5){
          width: 20%;
          img{
            width: 100%;
            height: auto;
            max-height: 256px;
          }
        }
        div:nth-last-child(-n+3){
            width: 33.33%;
            img{
              width: 100%;
              height: auto;
              max-height: 360px;
            }
        }
    }
    
}

.mobile{
    .title{
        font-family: PingFang SC;
        font-weight: 600;
        color: #040814;
    }
    .photo{
        img{
            width: 100%;
            height: 250px;
        } 
    }
}

`
