import React, { useEffect, useState } from 'react'
import { Banner } from '@/components/Banner'
import bannerImg from '@/assets/business/banner.jpg'
import bannerMobile from '@/assets/business/banner_mobile.png'
import { Tab } from '@/components/Tab'
import JindanPc from '@/assets/home/jindan-pc.png'
import YunpiaoPc from '@/assets/home/yunpiao-pc.jpg'
import RongziPc from '@/assets/home/rongzi-pc.jpg'
import { scrollToAnchor } from '@/utils/tool'
import { useLocation } from 'react-router-dom'

export const Business = () => {
  const tabs = [{
    title: '金单产品',
    path: ''
  }, {
    title: '运票产品',
    path: ''
  }, {
    title: '融资租赁',
    path: ''
  }]
  const [list, setList] = useState([{
    id: 'jindan',
    img: JindanPc,
    align: 'right',
    show: false,
    title: '金单产品',
    desc: '通过一路通平台，金单在产业链企业之间拆分、流转，实现核心企业信用多级传递，帮助中小微供应商企业快速实现应收账款资金回笼，解决融资难题。 ',
    listTitle: '产品价值优势',
    list: [{
      title: '1、提高融资效率',
      content: '融资全线上化操作，即申即用。'
    }, {
      title: '2、降低资金成本',
      content: '供应商可按需拆转拆融，增加资金使用效率，减少利息成本'
    }, {
      title: '3、多融资渠道',
      content: '供应商结合自身需求，可在平台选择向直连银行或保理机构贴现。'
    }, {
      title: '4、确权有保障',
      content: '每一笔金单由开单人出具付款函，开单即确权，应收有保障。'
    }]
  }, {
    id: 'yunpiao',
    img: YunpiaoPc,
    align: 'left',
    show: false,
    title: '运票产品',
    desc: '核心企业在宝湾一路通系统上，以电子签名方式，承诺在指定日期支付确定金额运费给承运人的应收账款债权凭证，是电子付款凭证与数字化物流运输场景高度结合的场景金融产品，旨在解决物流运输行业上下游结算账期错配难题。平台支持凭证的在线签发、融资等相关业务。',
    listTitle: '产品价值优势',
    list: [{
      title: '1、优化承运人供应链管理',
      content: '为承运人增加支付融通的金融工具，拓宽融资渠道，盘活供应链应收账款资产，提高供应链管理效率和竞争力'
    }, {
      title: '2、高效解决资金需求',
      content: '7×24 小时服务，线上融资业务申请最快当日受理、当日到账，快速解决资金需求，方便快捷'
    }, {
      title: '3、降低融资成本',
      content: '运票可持有、可融资，按融资天数计息，使用灵活，融资成本低'
    }, {
      title: '4、纯信用无抵押',
      content: '基于网货平台货运运单数据，提供无抵押、纯信用融资服务，提高资金周转效率'
    }]
  }, {
    id: 'rongzi',
    img: RongziPc,
    align: 'right',
    show: false,
    title: '融资租赁',
    desc: '出租人（资金方）根据承租人（融资人）对租赁物件的特定要求和对供货人的选择，出资向供货人购买租赁物件，并租给承租人使用，承租人则分期向出租人支付租金。有直接租赁、委托租赁、售后回租等方式，可根据承租人经营情况选定所需要的设备，满足企业经营生产所需的资金周转，助力企业提升经营效益。',
    listTitle: '产品价值优势',
    list: [{
      title: '1、盘活固定资产',
      content: '在承租人享有固定资产使用权的情况下，通过融资租赁（售后回租）的方式，将固定资产转为流动资金，盘活企业固定资产，为企业提供自由使用的运营资金，提升资产利用效率。'
    }, {
      title: '2、解决资金占压问题',
      content: '企业生产经营需要购置设备等固定资产，一次性投入需花费大量资金，通过融资租赁（直接租赁）的形式，将一次性购买支出转变为分期支付租金，有效降低一次性支付造成的资金占压，减轻资金压力。'
    }, {
      title: '3、加速企业资金周转',
      content: '企业将购买设备的资金，用于企业的经营周转，将长期占压的资金投入生产经营，加速企业资金周转，为企业创造更多利润。'
    }]
  }])

  const [active, setActive] = useState(0)
  const changeTabs = (index: number) => {
    setActive(index)
    scrollToAnchor(list[index].id)
  }

  // 页面滚动
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      const query = new URLSearchParams(location.search)
      const id = query.get('id')
      if (id) { scrollToAnchor(id) }
    }, 50)
  }, [location.key])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const scrollTop = document.documentElement.scrollTop
      const dataList = [...list]
      if (dataList.every(item => { return item.show })) return
      dataList.forEach((item) => {
        const element = document.getElementById(item.id)
        const top = element?.offsetTop || 0
        if (element && scrollTop >= top - 600) {
          item.show = true
        }
      })
      setList(dataList)
    })
  }, [])

  return (
    <div className='relative'>
      <Banner src={bannerImg} mobileSrc={bannerMobile} title='业务&产品'></Banner>
      <Tab list={tabs} active={active} onChange={changeTabs}></Tab>
      {
        list.map((item, index) =>
          <div key={index} id={item.id} className='relative overflow-hidden lg:h-[1080px] md:h-[1080px]'>
            <div className={`relative h-full ${item.show && `before:content before:block before:w-full before:h-full before:absolute before:left-0 before:top-0 before:right-0 before:bottom-0 ${item.align === 'left' ? 'before:bg-bgOdd' : 'before:bg-bgEven'} before:animate-scale1 before:delay-1000 before:scale-x-0`}`}>
              <img src={item.img} alt={item.title} className={`lg:h-full md:h-full sm:w-full object-cover hover:scale-105 transition-all duration-500 ease-in ${item.align === 'left' && 'lg:float-right md:float-right'}`} />
            </div>
            <div className={`absolute ${item.align === 'left' ? 'left-0 pr-12 pl-[18%] md:pl-4 bg-bgOdd' : 'right-0 pl-12 pr-[18%] md:pr-4 bg-bgEven'} top-0 bottom-0 text-white w-1/2 pt-60 sm:relative sm:w-full sm:pt-8 sm:px-4 sm:pb-8`}>
              <div className={`${item.show ? `${item.align === 'left' ? 'animate-fadeInRight50' : 'animate-fadeInLeft50'} opacity-1` : 'opacity-0'} transition-all duration-1000 delay-1000`}>
                <div className='text-[32px] font-semibold sm:text-xl'>{item.title}</div>
                <div className='w-[32px] h-[4px] sm:w-4 sm:h-[2px] mt-2 bg-white relative after:content after:block after:absolute after:w-0 after:h-0 after:-right-[4px] sm:after:-right-[2px] after:border-b-[4px] sm:after:border-b-[2px] after:border-b-white after:border-r-[4px] sm:after:border-r-[2px] after:border-r-transparent after:border-solid'></div>
                <div className='sm:text-sm sm:text-white/[65%] text-base text-white/[85%] mt-6'>{item.desc}</div>
                <div className='sm:text-base text-lg text-white mt-8'>{item.listTitle}</div>
                <ul>
                  {
                    item.list.map(subItem => <li key={subItem.title}>
                      <div className='sm:text-sm text-base text-white mt-4'>{subItem.title}</div>
                      <div className='sm:text-sm sm:text-white/[65%] text-base text-white/[85%] mt-2'>{subItem.content}</div>
                    </li>)
                  }
                </ul>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}
