import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

interface BannerProps {
  title: string
  src: string
  mobileSrc: string
}
export const Banner = ({ title, src, mobileSrc }: BannerProps) => {
  const [opacity, setOpacity] = useState(false)
  const location = useLocation()
  useEffect(() => {
    setOpacity(true)
  }, [location.key])
  return <div className='relative'>
    <img className='sm:hidden w-auto' src={src} />
    <img className='lg:hidden md:hidden' src={mobileSrc} />
    <div className={`absolute transition-all ${opacity ? 'opacity-100' : 'opacity-0'} delay-700 sm:top-14 sm:left-4 sm:text-2xl top-0 left-0 right-0 bottom-0 lg:flex md:flex items-center justify-center text-4xl font-semibold text-white animate-fadeInUp50`}>
      {title}
    </div>
  </div>
}
