import React from 'react'
import styled from 'styled-components'
import Culture1 from '@/assets/about/corporate_culture1.jpg'
import Culture2 from '@/assets/about/corporate_culture2.jpg'
import Culture3 from '@/assets/about/corporate_culture3.png'


const list = [
  {
    img: Culture1,
    name: '行为准则',
    texts: ['以客户为中心、以业务为导向，以风险为底线', '面对未来、正面思考，办法总比困难多', '说到做到超越期望']
  },
  {
    img: Culture2,
    name: '愿景使命',
    texts: ['成为具有南山集团产业特色的专业供应链金融服务平台', '通过产融互促，实现产融结合，协同集团产业转型升级,为提升产业价值赋能']
  },
  {
    img: Culture3,
    name: '核心价值观',
    texts: ['绽放自我，不负韶华']
  }
]

export const Culture = () => {

  return (
    <Wrapper>

      <div className='sm:hidden pc pt-44'>
        <div className="title">企业文化</div>
        <div className="info mt-12">
          {
            list.map((item, index) =>
              <div className="info-item w-full flex justify-between items-center" key={`pc-${item.name}`}>
                {
                  !(index % 2) ? <div className='w-2/4 overflow-hidden'>
                    <img className='object-cover hover:scale-105 transition-all duration-500 ease-in' src={item.img} alt="宝湾资本" />
                  </div> : ''
                }

                <div className="info-item-continer w-2/4 pl-12 flex flex-col justify-center">
                  <div className='title relative text-xl'>{item.name}</div>
                  <div className='mt-12'>
                    {
                      item.texts.map((k, index) =>
                        <div className='mt-3 text' key={`pc-text-${index}`}>{k}</div>
                      )
                    }
                  </div>
                </div>
                {
                  (index % 2) ? <div className='w-2/4 overflow-hidden'>
                    <img className='object-cover hover:scale-105 transition-all duration-500 ease-in' src={item.img} alt="宝湾资本" />
                  </div> : ''
                }
              </div>
            )
          }
        </div>

      </div>

      <div className='md:hidden lg:hidden mobile w-full pt-12'>
        <div className="title text-xl pl-4">企业文化</div>
        <div className="info mt-1">
          {
            list.map(item =>
              <div className="info-item pt-6" key={item.name}>
                <img src={item.img} alt="宝湾资本" />
                <div className="info-item-continer">
                  <div className='title relative text-xl'>{item.name}</div>
                  <div className='mt-6'>
                    {
                      item.texts.map((k, index) =>
                        <div className='mt-1 text' key={`text-${index}`}>{k}</div>
                      )
                    }
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
.pc{
  .title{
    width: 1200px;
    margin: 0 auto;
    font-size: 40px;
  }
  .info-item{
    font-family: PingFang SC;
    background: #F4F4F7;
    img{
      display: inline-block;
      width: 100%;
      height: auto;
      max-height: 480px;
    }
    .info-item-continer{
      height: 100%;
      .title{
      font-size: 40px;
      font-weight: 600;
      color: #040814;
    }
    .title::after{
      display: inline-block;
      content: " ";
      width: 32px;
      height: 2px;
      background: #0169CB;
      position: absolute;
      left: 2px;
      bottom: -16px;
    }
    .text{
      color: #43464F;
    }
    }
  }
}

.mobile{
    .title{
        font-family: PingFang SC;
        font-weight: 600;
        color: #040814;
    }
    .info-item{
        img{
            width: 100%;
            height: 188px;
        }
        .info-item-continer{
            padding: 24px 16px;
            background: #F4F4F7;
            .title::after{
                display: inline-block;
                content: " ";
                width: 24px;
                height: 2px;
                background: #0169CB;
                position: absolute;
                left: 0;
                bottom: -4px;
            }
        }
        .text{
      color: #43464F;
    }
    }
}

`
