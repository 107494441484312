import React from 'react'
import { Header } from '@/components/Header/index'
import { Footer } from '@/components/Footer'
import { Outlet } from 'react-router-dom'

export const Main = () => {
  return (
    <div className='overflow-x-hidden'>
      <Header></Header>
      <Outlet></Outlet>
      <Footer></Footer>
    </div>
  )
}
