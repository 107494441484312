import React, { useEffect, useState } from 'react'
import CreateDate from '@/assets/date.png'
import { useLocation } from 'react-router-dom'
import { NewsItems, findNewDetail } from '@/api/news'
import { styled } from 'styled-components'
import { Breadcrumbs } from '../components/Breadcrumbs'
import moment from 'moment'

export const NewsDetail = () => {
  const breadcrumbs = [{
    title: '首页',
    path: '/'
  }, {
    title: '新闻中心',
    path: '/news'
  }, {
    title: '公司动态',
    path: '/news/list'
  }, {
    title: '文章详情',
    path: ''
  }]

  const location = useLocation()
  const [data, setData] = useState<NewsItems>()
  const getDetail = () => {
    const query = new URLSearchParams(location.search)
    const id = query.get('id')
    id && findNewDetail(id).then(res => {
      setData(res.data.data)
    }).catch(err => {
      console.log(err)
    })
  }
  useEffect(() => {
    getDetail()
  }, [])
  return (
    <div>
      <Breadcrumbs list={breadcrumbs}></Breadcrumbs>
      <div className='lg:w-[840px] md:w-[840px] pt-16 sm:w-full sm:pt-8 px-4 pb-12 mx-auto '>
        <div className="text-textPrimary text-4xl sm:text-xl">{data?.title}</div>
        <div className='border-b border-borderOne text-textTertiary flex items-center mt-6 pb-4 sm:mt-4 sm:pb-3'>
          <img className='w-4 h-4' src={CreateDate} alt="宝湾资本" />
          <div className='ml-1 text-sm'>{moment(data?.createTime).format('YYYY.MM.DD')}</div>
        </div>
        <PcContent className='sm:hidden' dangerouslySetInnerHTML={{ __html: data?.content || '' }}></PcContent>
        <MobileContent className='lg:hidden md:hidden' dangerouslySetInnerHTML={{ __html: data?.content || '' }}></MobileContent>
      </div>
    </div>
  )
}

const PcContent = styled.div`
  padding-top: 64px;
  font-size: 16px;
  p {
    padding-bottom: 32px;
  }
  section {
    padding-bottom: 16px;
  }
  img {
    width: 100%;
    padding-bottom: 32px;
  }
`

const MobileContent = styled.div`
  padding-top: 32px;
  font-size: 14px;
  p {
    padding-bottom: 16px;
  }
  section {
    padding-bottom: 10px;
  }
  img {
    width: 100%;
    padding-bottom: 16px;
  }
`
