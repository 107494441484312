
import Request from '@/utils/request'

interface NewsRequest {
  typeId: number
  pageNum: number
  pageSize: number
}
export interface NewsItems {
  id: string
  title: string
  content: string
  image: string
  createTime: string
}

interface NewsResponse {
  data: NewsItems[]
  total: number
}

/**
 * 获取公司动态
 */
export const findNews = (params: NewsRequest) => {
  return Request.post<NewsResponse>('/front/news/findHomeNews', params)
}


/**
 * 获取新闻详情
 */
export const findNewDetail = (id: string) => {
  return Request.get(`/front/news/queryNewsById?id=${id}`)
}
