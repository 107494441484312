import React from 'react'
import styled from 'styled-components'
import Img1 from '@/assets/about/aboutus_img1.png'
import Img2 from '@/assets/about/aboutus_img2.png'
import Img3 from '@/assets/about/aboutus_img3.png'

const describe = [
  {
    bg: Img1,
    name: '强场景',
    text: '强场景是产融最重要的护城河，要以场景为核心，将金融、风险、业务、产品前移到产业场景中去，嵌入场景中关键环节。共建数字化场景，信用支付、网络货运、云仓云配等'
  },
  {
    bg: Img2,
    name: '强科技',
    text: '以数字化为纽带，推动产业数字化，数字金融化，打造支撑产业金融的强科技体系，包括信贷核心系统，金单本地化系统，账户体系等。'
  },
  {
    bg: Img3,
    name: '全面风险管理体系',
    text: '建立穿透到场景的风险管控，授信审批工作组、风险管理委员会、授信前中后制度体系。'
  }
]


export const Introduce = () => {

  return (
    <Wrapper>
      <div className='sm:hidden pc pt-32 pb-32'>
        <div className="title">公司介绍</div>
        <div className='info mt-8'>宝湾资本是中国南山集团的产业金融平台，成立于2018年，依托于集团旗下产业资源，以金融科技为抓手，深耕产业生态圈，专注于为中国南山集团旗下产业生态圈提供金融服务整体解决方案，推动传统产业升级转型。</div>
        <div className="modal mt-8 text-lg">商业模式</div>
        <div className="info mt-2">聚焦集团主业，挖掘集团产业链上可视化的供应链资产，形成基于集团产业风险闭环的标准化产品，借助科技手段，通过自有资产资金投放，引入外部优质金融资源，服务集团产业上下游实体经济，实现产融结合，以融促产。</div>
        <div className="describe grid grid-cols-3 gap-3 mt-8">
          {
            describe.map(item =>
              <div className="describe-item text-xl" key={item.name}>
                <div className="describe-item-top" style={{ background: `url(${item.bg})` }}>{item.name}</div>
                <div className="describe-item-text mt-6 text-base">{item.text}</div>
              </div>
            )
          }
        </div>
      </div>
      <div className='md:hidden lg:hidden mobile w-full'>
        <div className="title text-xl">公司介绍</div>
        <div className='info mt-6 text-sm'>宝湾资本是中国南山集团的产业金融平台，成立于2018年，依托于集团旗下产业资源，以金融科技为抓手，深耕产业生态圈，专注于为中国南山集团旗下产业生态圈提供金融服务整体解决方案，推动传统产业升级转型。</div>
        <div className="modal mt-6 text-base">商业模式</div>
        <div className="info mt-2">聚焦集团主业，挖掘集团产业链上可视化的供应链资产，形成基于集团产业风险闭环的标准化产品，借助科技手段，通过自有资产资金投放，引入外部优质金融资源，服务集团产业上下游实体经济，实现产融结合，以融促产。</div>
        <div className="describe mt-8">
          {
            describe.map(item =>
              <div className="describe-item w-full text-xl mt-2" key={item.name}>
                <div className="describe-item-top" style={{ background: `url(${item.bg})` }}>{item.name}</div>
                <div className="describe-item-text mt-2 text-base">{item.text}</div>
              </div>
            )
          }
        </div>
      </div>
    </Wrapper>
  )

}

const Wrapper = styled.div`
    width: 100%;
    background: #F4F4F7;
    .pc{
        width: 1200px;
        margin: 0 auto;
        font-family: PingFang SC;
        .title{
            font-size: 40px;
            font-weight: 600;
            color: #040814;
        }
        .info{ 
            color: #43464F;
            
        }
        .modal{  

        color: #040814;
        font-weight: 500;
        }
        .describe-item{
            height: 360px;
            background: #0169CB;
            .describe-item-top{
                height: 212px;
                background-size: 100% 100% !important;
                padding: 174px 24px 0;
                color: #fff;
                font-family: PingFang SC;
                font-weight: 600;
            }
            .describe-item-text{
             padding:0 24px;   
            color: #FFFFFF;
            }
        }
    }
    .mobile{
        padding: 48px 16px;
        .title{
            padding: 0 16px;
            font-weight: 600;
            color: #040814;
        }
        .info{ 
            color: #43464F;
        }
        .modal{  
        color: #040814;
        font-weight: 500;
        }
        .describe-item{
            height: 360px;
            background: #0169CB;
            .describe-item-top{
                height: 212px;
                background-size: 100% 100% !important;
                padding: 174px 24px 0;
                color: #fff;
                font-family: PingFang SC;
                font-weight: 600;
            }
            .describe-item-text{
             padding:0 24px;   
            color: #FFFFFF;
            }
        }
    }
`
