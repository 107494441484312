/* eslint-disable quotes */
import React, { useEffect } from "react"
import { load } from "@amap/amap-jsapi-loader"
import Location from '@/assets/location.png'

interface IProps {
  mapId: string,
  height:string
}

const MapComponent = (props:IProps) => {
  const { mapId, height } = props
  useEffect(() => {
    const initializeMap = async () => {
      const AMap = await load({
        key: "d47be4ab51cd7436e70b15a1be94139d",
        version: "2.0", // 版本号根据需求而定
        plugins: ["AMap.Geolocation"],
      })

      const map = new AMap.Map(mapId, {
        center: [113.89568, 22.47906],
        zoom: 17
      })

      AMap.plugin("AMap.Geolocation", function() {
        const geolocation = new AMap.Geolocation({
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
          showButton: false,
          buttonPosition: "LB",
          buttonOffset: new AMap.Pixel(10, 20),
          showMarker: true,
          showCircle: true,
          panToLocation: true,
          zoomToAccuracy: true,
        })

        map.addControl(geolocation)

        const icon = new AMap.Icon({
          size: new AMap.Size(36, 36), // 设置图标大小
          image: Location, // 图标 URL
        })

        const marker = new AMap.Marker({
          position: [113.89568, 22.47906],
          icon,
        })
        map.add(marker)
      })
    }

    initializeMap()
  }, [mapId])

  return <>
    <div id={mapId} style={{ width: "100%", height: `${height}` }}></div>
  </>
}

export default MapComponent
