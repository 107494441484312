import React, { RefObject, createRef, useEffect, useState } from 'react'

interface TabProps {
  list: {
    title: string
    path: string
  }[],
  active: number,
  onChange: (index: number) => void
}
export const Tab = ({ list, active, onChange }: TabProps) => {
  const navRef: RefObject<HTMLDivElement> = createRef()
  const [isFixed, setIsFixed] = useState(false)
  useEffect(() => {

    const handleScroll = () => {
      const navElement = document.getElementById('nav')
      const navTop = navElement?.offsetTop || 0
      const navHeight = navElement?.offsetHeight || 0
      const scrollTop = document.documentElement.scrollTop
      const isFixed = scrollTop >= navTop + navHeight
      if (navTop && navHeight) {
        setIsFixed(isFixed)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  const changeTabs = (index: number) => {
    onChange(index)
  }
  return <div>
    {/* 置顶的 */}
    {
      isFixed && <div className="lg:fixed md:fixed sm:fixed top-0 left-0 right-0 z-10 w-full bg-white">
        <ul className='sm:w-full sm:pl-3 md:w-11/12 lg:w-[1200px] mx-auto flex'>
          {
            list.map((item, index) => <li
              key={item.title}
              onClick={() => changeTabs(index)}
              className={`${active === index ? 'text-textPrimary border-b-2 border-primary' : 'text-textTertiary hover:text-textPrimary hover:border-b-2 hover:border-primary'} mr-6 text-base sm:text-sm py-4 sm:py-3 cursor-pointer`}
            >{item.title}</li>)
          }
        </ul>
      </div>
    }
    {/* 页面文档流中 */}
    <div id='nav' className="w-full bg-white" ref={navRef}>
      <ul className='sm:w-full sm:pl-3 md:w-11/12 lg:w-[1200px] mx-auto flex'>
        {
          list.map((item, index) => <li
            key={item.title}
            onClick={() => changeTabs(index)}
            className={`${active === index ? 'text-textPrimary border-b-2 border-primary' : 'text-textTertiary hover:text-textPrimary hover:border-b-2 hover:border-primary'} mr-8 text-base sm:text-sm py-4 sm:py-3 cursor-pointer`}
          >{item.title}</li>)
        }
      </ul>
    </div>
  </div>
}
