import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import styled from 'styled-components'
import Home_banner1 from '@/assets/home/home_banner1.jpg'
import Home_banner2 from '@/assets/home/home_banner2.jpg'
import Home_banner3 from '@/assets/home/home_banner3.jpg'
import Home_mobile_banner1 from '@/assets/home/banner1-mobile.png'
import Home_mobile_banner2 from '@/assets/home/banner2_mobile.png'
import Home_mobile_banner3 from '@/assets/home/banner3_mobile.png'
import LeftIcon from '@/assets/left.png'
import RigthIcon from '@/assets/right.png'

const bannerText = [
  {
    title: '我们的愿景',
    text: '成为具有中国南山产业特色的供应链金融服务平台',
    bg: Home_banner1,
    mobileBg: Home_mobile_banner1
  },
  {
    title: '使命',
    text: '赋能产业数字化转型升级   优化产业供应链管理   提升产业价值创造能力',
    bg: Home_banner2,
    mobileBg: Home_mobile_banner2
  },
  {
    title: '',
    text: '推动产融结合、赋能集团主业',
    bg: Home_banner3,
    mobileBg: Home_mobile_banner3
  },
]

export const Banner = () => {

  const [activeIndex, setActiveIndex] = useState(0)

  const handleSlideChange = (swiper: { realIndex: React.SetStateAction<number> }) => {
    setActiveIndex(swiper.realIndex)
  }

  return (
    <Wrapper id='homeBanner'>
      <div className='sm:hidden pc w-full'>
        <Swiper
          modules={[Navigation]}
          navigation
          loop
          spaceBetween={50}
          slidesPerView={1}
          effect="cube"
          speed={1500}
          onSlideChange={handleSlideChange}
        >
          {
            bannerText.map((item, index) => <SwiperSlide key={item.text}>
              <PcSlide style={{ background: `url(${item.bg})` }} className={`${activeIndex === index ? 'slide-animation' : ''}`}>
                <div className='slide-continer'>
                  {
                    item.title && <div className='title'>{item.title}</div>
                  }
                  <div className='text'>{item.text}</div>
                </div>
              </PcSlide>
            </SwiperSlide>)
          }
        </Swiper>
      </div>
      <div className='w-full md:hidden lg:hidden mobile'>
        <Swiper
          loop
          modules={[Pagination]}
          pagination={{ clickable: true }}
          spaceBetween={50}
          slidesPerView={1}
          effect="cube"
          speed={1500}
        >
          {
            bannerText.map(item => <SwiperSlide key={item.text}>
              <Slide style={{ background: `url(${item.mobileBg})` }}>
                {
                  item.title && <div className='title text-2xl'>{item.title}</div>
                }
                <div className='text text-xl mt-6'>{item.text}</div>
              </Slide>
            </SwiperSlide>)
          }
        </Swiper>

      </div>

    </Wrapper>
  )
}


const Wrapper = styled.div`

@keyframes slideInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.pc{
.swiper-button-prev{
    left: 104px;
}

.swiper-button-next{
    right: 104px;
}

.swiper-button-next, .swiper-button-prev{
    display: none;
    width: 64px;
    height: 64px;
}

.swiper-button-next::after{
    content: ' ';
    display: inline-block;
    width: 100%;
    height: 100%;
    background: url(${RigthIcon});
    background-size: 100% 100%;
}

.swiper-button-prev::after{
    content: ' ';
    display: inline-block;
    width: 100%;
    height: 100%;
    background: url(${LeftIcon});
    background-size: 100% 100%;
}

.swiper:hover .swiper-button-next,
.swiper:hover .swiper-button-prev {
  display: block !important;
}
.slide-animation{
  .slide-continer{
    animation: slideInUp 2s forwards;
  }
}
}
`

const PcSlide = styled.div`
  padding-top:320px;
   width: 100%;
  height: 100vh;
  background-size: cover !important;
  .title{
    text-align: center;
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 600;
    line-height: 0px;
    color: #FFFFFF;
    position: relative;
    &::after{
        display: inline-block;
        position: absolute;
        bottom: -40px;
        left: 50%;
        transform: translateX(-50%);
        content:" ";
        width: 32px;
        height: 4px;
        background: #FFFFFF;
    }
}
.text{
    text-align: center;
    margin-top: 56px;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    
}
`
const Slide = styled.div`
    padding: 144px 16px 0;
   width: 100%;
   height: 480px;
  background-size: 100% 100% !important;
  .title,.text{
    position: relative;
    color: #FFFFFF;
    font-family: PingFang SC;
    font-weight: 600;
  }
.title::after{
    content: " ";
    display: inline-block;
    width: 16px;
    height: 2px;
    background: #FFFFFF;
    position: absolute;
    bottom: -8px;
    left: 2px;
}
.swiper-pagination-bullet{
    width: 8px;
    height: 8px;
    background: #FFFFFF;
    border-radius: 50%;
    opacity: 0.2;
}
.swiper-pagination-bullet-active{  
    width: 8px;
    height: 8px;
    background: #FFFFFF !important;
    border-radius: 50%;
    opacity: 0.4;
}
`
