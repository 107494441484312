import React, { useEffect, useState } from 'react'
import { Banner } from '@/components/Banner'
import NewsBannerLg from '@/assets/news/news_banner.jpg'
import NewsBannerSm from '@/assets/news/newsbanner_mobile.png'
import next_icon from '@/assets/next.png'
import { useNavigate } from 'react-router-dom'
import { NewsItems, findNews } from '@/api/news'
import DefaultImg from '@/assets/news/news_default_img.png'

export const News = () => {
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    setVisible(true)
  }, [])
  const navigate = useNavigate()
  const pageSize = 3
  const [list, setList] = useState<NewsItems[]>([])
  const getNews = () => {
    findNews({
      typeId: 4,
      pageNum: 1,
      pageSize: pageSize
    }).then(res => {
      setList(res.data.data || [])
    }).catch(err => {
      console.log(err)
    })
  }
  useEffect(() => {
    getNews()
  }, [])

  const toDetail = (id: string) => {
    navigate(`/news/detail?id=${id}`)
  }

  return (
    <div>
      <Banner src={NewsBannerLg} mobileSrc={NewsBannerSm} title='新闻中心'></Banner>
      <div className='sm:w-full md:w-11/12 lg:w-[1200px] mt-[184px] mb-[128px] sm:mt-[48px] sm:mb-[45px] sm:px-4 mx-auto'>
        <div className='flex justify-between pb-12 sm:pb-6'>
          <div className={`${visible ? 'opacity-1' : 'opacity-0'} text-4xl font-semibold sm:text-xl lg:animate-fadeInUp50 md:animate-fadeInUp50 transition-opacity duration-1000 delay-700`}>公司动态</div>
          <div className={`${visible ? 'opacity-1' : 'opacity-0'} flex items-center text-base sm:text-sm text-primary lg:animate-fadeInUp50 md:animate-fadeInUp50 transition-opacity duration-1000 delay-700 cursor-pointer`} onClick={() => { navigate('/news/list') }}>
            查看更多
            <img className='w-4 h-4 ml-1' src={next_icon} />
          </div>
        </div>
        <div className={`w-full ${list.length > 1 ? 'lg:h-[600px] md:pb-[49.5%]' : ''}`}>
          {
            list.map((item, index) => <div key={index} className={`${visible ? 'opacity-1' : 'opacity-0'} ${list.length === 1 ? 'w-full pb-[75%] md:w-full md:pb-[75%]' : index === 0 ? 'lg:w-[800px] lg:h-[600px] lg:float-left md:w-4/6 md:h-0 md:pb-[49.5%] md:float-left lg:animate-fadeInLeft50 md:animate-fadeInLeft50 transition-opacity duration-1000 delay-700' : 'lg:w-[384px] lg:h-[288px] lg:mb-6 lg:float-right md:w-[32%] md:h-0 md:pb-[24%] md:float-right lg:animate-fadeInRight50 md:animate-fadeInRight50 transition-opacity duration-1000  delay-700'} cursor-pointer relative overflow-hidden sm:w-full sm:pb-[75%] md:mb-3 sm:mb-3`} onClick={() => { toDetail(item.id) }}>
              <img className='w-full h-full absolute object-cover hover:scale-105 transition-all duration-500 ease-in' src={item.image || DefaultImg} alt="" />
              <div className='absolute bottom-6 left-6 right-6 text-2xl sm:text-base text-white line-clamp-1'>{item.title}</div>
            </div>)
          }
        </div>
      </div>
    </div>
  )
}

