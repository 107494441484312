import React, { useEffect, useRef, useState } from 'react'
import { Breadcrumbs } from '../components/Breadcrumbs/index'
import { styled } from 'styled-components'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import { Color } from '@/styles/color'
import DefaultImg from '@/assets/news/news_default_img.png'
import more_icon from '@/assets/showMore.png'
import { NewsItems, findNews } from '@/api/news'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

export const NewsList = () => {
  const breadcrumbs = [{
    title: '首页',
    path: '/'
  }, {
    title: '新闻中心',
    path: '/news'
  }, {
    title: '公司动态'
  }]
  const [current, setCurrent] = useState(1)
  const pageSize = 10
  const [total, setTotal] = useState(1)
  const [list, setList] = useState<NewsItems[]>([])
  const more = useRef(false)

  const getNews = () => {
    findNews({
      typeId: 4,
      pageNum: current,
      pageSize: pageSize
    }).then(res => {
      setTotal(res.data.total)
      if (more.current) {
        setList(list.concat(res.data.data))
      } else {
        setList(res.data.data || [])
      }
    }).catch(err => {
      console.log(err)
    })
  }
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  useEffect(() => {
    toTop()
  }, [])
  useEffect(() => {
    getNews()
  }, [current])

  const navigate = useNavigate()
  const toDetail = (id: string) => {
    navigate(`/news/detail?id=${id}`)
  }

  const onCurrentChange = (num: number) => {
    more.current = false
    setCurrent(num)
  }
  const getMore = () => {
    more.current = true
    setCurrent(current + 1)
  }

  return <div>
    <Breadcrumbs list={breadcrumbs}></Breadcrumbs>
    <div className='sm:w-full sm:px-4 md:w-11/12 lg:w-[1200px] mx-auto'>
      <div className='text-4xl text-textPrimary font-semibold pt-16 sm:text-xl sm:pt-8'>公司动态</div>
      <ul className='pb-16 sm:pb-6'>
        {
          list.map((item, index) => <li key={index} className='group flex justify-between pt-16 sm:pt-4 cursor-pointer border-b border-b-borderOne pb-2' onClick={() => { toDetail(item.id) }}>
            <div className='flex-1 pr-16 sm:pr-6 sm:w-2/3'>
              <div className='text-[#BE985F] text-xl sm:text-base'>{moment(item?.createTime).format('YYYY.MM.DD')}</div>
              <div className='group-hover:text-primary text-xl sm:text-base text-textPrimary font-medium mt-2 sm:mt-1 sm:line-clamp-1 line-clamp-2'>{item.title}</div>
              <Content className='text-base sm:text-sm text-textSecondary mt-4 sm:mt-2 sm:line-clamp-1 sm:h-5 line-clamp-2' dangerouslySetInnerHTML={{ __html: item.content }}></Content>
            </div>
            <div className='w-[360px] h-[202px] sm:w-[120px] sm:h-[68px] overflow-hidden'>
              <img className='w-full h-full object-cover hover:scale-105 transition-all duration-500 ease-in' src={item.image || DefaultImg} />
            </div>
          </li>)
        }
      </ul>
      <div className='sm:hidden flex justify-center pb-4'>
        <CustomPagination current={current} style={{ color: 'red' }} pageSize={pageSize} total={total} onChange={onCurrentChange}></CustomPagination>
      </div>
      {
        total > list.length && <div className='lg:hidden md:hidden pb-12'>
          <div className='flex items-center justify-center mx-auto w-[128px] h-10 border border-[#E3E3E6] text-textTertiary rounded-3xl' onClick={getMore}>
            显示更多
            <img className='w-5 h-5 ml-[10px]' src={more_icon} />
          </div>
        </div>
      }
    </div>

  </div>
}

const CustomPagination = styled(Pagination)`
  .rc-pagination-item {
    width: 48px;
    height: 48px;
    line-height: 48px;
    background-color: ${Color.BorderLineOne};
    font-size: 16px;
    border-radius: 8px;
    border: 0;
    &>a {
      color: ${Color.TextSecondary};
    }
    &>a:hover {
      color: ${Color.WhiteBG};
      background-color: ${Color.PrimaryBG};
      border-radius: 8px;
    }
  }
  .rc-pagination-item-active {
    background-color: ${Color.PrimaryBG};
    &>a {
      color: ${Color.WhiteBG};
    }
  }
  .rc-pagination-prev, .rc-pagination-next {
    width: 48px;
    height: 48px;
    line-height: 48px;
    color: ${Color.TextSecondary};
    .rc-pagination-item-link {
      background-color: ${Color.BorderLineOne};
      font-size: 16px;
      border-radius: 8px;
      border: 0;
    }
    &>button::after {
      font-size: 18px;
    }
  }
  .rc-pagination-jump-next {
    width: 48px;
    height: 48px;
    line-height: 48px;
    background-color: ${Color.BorderLineOne};
    font-size: 16px;
    border-radius: 8px;
    &>button:after {
      font-size: 14px;
    }
    &>button:hover:after {
      color: ${Color.PrimaryBG}
    }
  }
`

const Content = styled.div`
  img {
    display: none;
  }
`
