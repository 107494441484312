import React, { ReactNode } from 'react'
import { Home } from '@/views/home'
import { News } from '@/views/news/index'
import { About } from '@/views/about'
import { Business } from '@/views/business'
import { NewsDetail } from '@/views/news/Detail'
import { NewsList } from '@/views/news/List'

export type MenuInfo = {
  title: string
  path: string
  page?: ReactNode
  show?: boolean
  children?: MenuInfo[]
}

export const Menus: MenuInfo[] = [
  {
    title: '首页',
    path: '/',
    page: <Home />,
  },
  {
    title: '新闻中心',
    path: '/news',
    page: <News />
  },
  {
    title: '公司动态',
    path: '/news/list',
    page: <NewsList />,
  },
  {
    title: '新闻详情',
    path: '/news/detail',
    page: <NewsDetail />,
  },
  {
    title: '关于我们',
    path: '/about',
    page: <About />,
  },
  {
    title: '业务&产品',
    path: '/business',
    page: <Business />
  }
]

// 业务&产品
export const BusinessMenu: MenuInfo = {
  title: '业务&产品',
  path: '/business',
  children: [{
    title: '金单产品',
    path: '/business?id=jindan'
  }, {
    title: '运票产品',
    path: '/business?id=yunpiao'
  }, {
    title: '融资租赁',
    path: '/business?id=rongzi'
  }]
}

// 关于我们菜单
export const AboutMenu: MenuInfo = {
  title: '关于我们',
  path: '/about',
  children: [{
    title: '公司介绍',
    path: '/about?id=introduce'
  }, {
    title: '企业文化',
    path: '/about?id=culture'
  }, {
    title: '团队建设',
    path: '/about?id=team'
  }, {
    title: '联系我们',
    path: '/about?id=contact'
  }]
}

export const NewsMenu: MenuInfo = {
  title: '新闻中心',
  path: '/news',
  children: [{
    title: '公司动态',
    path: '/news/list'
  }, {
    title: '集团动态',
    path: 'https://www.cndi.com/index.php/home/index/index.html'
  }]
}

// pc端header
export const HeaderMenu: MenuInfo[] = [
  { ...BusinessMenu },
  {
    title: '新闻中心',
    path: '/news'
  },
  { ...AboutMenu },
  {
    title: '业务平台',
    path: '',
    children: [{
      title: '网货平台',
      path: 'https://tyv2.newchiwan.com/login?redirect=/index'
    }, {
      title: '一路通',
      path: 'http://www.bwylt.com/#/home-page'
    }]
  }
]

// 移动端header
export const HeaderNavMenu: MenuInfo[] = [
  {
    title: '首页',
    path: '/'
  },
  { ...BusinessMenu },
  { ...NewsMenu },
  { ...AboutMenu }
]

export const FooterMenu: MenuInfo[] = [
  { ...BusinessMenu },
  { ...NewsMenu },
  { ...AboutMenu }
]
