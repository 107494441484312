import React from 'react'
import styled from 'styled-components'
import Jindan_golden from '@/assets/home/jindan_golden_icon.png'
import Jindan_white from '@/assets/home/jindan_white_icon.png'
import Rongzi_golden from '@/assets/home/rongzi_golden_icon.png'
import Rongzi_white from '@/assets/home/rongzi_white_icon.png'
import Yunpiao_white from '@/assets/home/yunpiao_white_icon.png'
import Yunpiao_golden from '@/assets/home/yunpiao_golden_icon.png'

type Props={
    current:number,
    change:(current:number)=>void
  }

const tabs = [
  {
    title: '金单产品',
    icon: Jindan_golden,
    active: Jindan_white
  },
  {
    title: '运票产品',
    icon: Yunpiao_golden,
    active: Yunpiao_white
  },
  {
    title: '融资租赁',
    icon: Rongzi_golden,
    active: Rongzi_white
  }
]


export const ProductTabs = (props:Props) => {

  const { current, change } = props

  return (
    <Wrapper>
      <div className='w-full flex custom-pagination'>
        {
          tabs.map((item, index) => <div className={`flex-1 tab ${current === index ? 'active' : ''}`} key={item.title} onMouseEnter={() => change(index)}>
            <div className='sm:hidden flex items-center pc pl-8 cursor-pointer'>
              <img className='w-16 mr-8' src={current === index ? item.active : item.icon} alt="宝湾资本" />
              <div>{item.title}</div>
            </div>
            <div className='md:hidden lg:hidden flex items-center mobile pl-2 cursor-pointer'>
              <img className='w-4 mr-2' src={current === index ? item.active : item.icon} alt="宝湾资本" />
              <div>{item.title}</div>
            </div>
          </div>)
        }
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`

  padding: 0;
  .tab{
    font-family: PingFang SC;
    font-weight: 600;
    color: #43464F;
    background:#fff
  }

  .active{
        color: #FFFFFF !important;
        background: #0169CB !important;
    }
  .pc{
    font-size: 24px;
    height: 84px;
  }
  .mobile{
    font-size: 14px;
    height: 44px;
  }
`
