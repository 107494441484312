import React from 'react'
import styled from 'styled-components'
import Nanshan from '@/assets/nanshan_logo_icon.png'
import Next from '@/assets/next.png'
import NanshanImg from '@/assets/home/nanshan_img.png'
import ChanchengImg from '@/assets/home/chancheng_img.png'
import NewchiwanImg from '@/assets/home/newchiwan_img.png'
import BlogisImg from '@/assets/home/blogis_img.png'
import Watermark from '@/assets/logo_watermark.png'

interface Company {
  title: string,
  bg: string,
  url: string
}

const companyInfo: Company[] = [
  {
    title: '新南山控股',
    bg: NanshanImg,
    url: 'https://www.xnskg.cn'
  },
  {
    title: '宝湾物流',
    bg: BlogisImg,
    url: 'http://www.blogis.com.cn'
  },
  {
    title: '东方物流',
    bg: NewchiwanImg,
    url: 'https://www.newchiwan.com'
  },
  {
    title: '宝湾产城',
    bg: ChanchengImg,
    url: 'http://www.baowan.com.cn'
  },
]


export const CliqueInfo = () => {

  const showCompany = () => (
    <>
      {
        companyInfo.map((item) => (
          <div className='other-item flex justify-between items-center h' key={item.title}>
            <div className="other-item-left flex-auto">
              <div className="other-item-left-title">{item.title}</div>
              <div className="other-item-left-link mt-10 flex items-center cursor-pointer" onClick={() => toJump(item.url)}>
                <div className='text-sm'>{item.title}官网</div>
                <img src={Next} alt="中国南山" />
              </div>
            </div>
            <div className="other-item-right w-40 h-full" style={{ background: `url(${item.bg})` }}></div>
          </div>
        ))
      }
    </>
  )

  const toJump = (url: string) => {
    window.open(url)
  }

  return (
    <Wrapper>
      <div className='sm:hidden pc pt-44 flex justify-between items-center pb-32'>
        <div className="pc-clique">
          <div className="pc-clique-logo flex items-center">
            <img src={Nanshan} alt="中国南山" />
            <div className='ml-5 text-xl'>旗下</div>
          </div>
          <div className="pc-clique-platform mt-16">
            <div className='text-2xl cn'>综合物流平台</div>
            <div className='mt-2 en'>INTEGRATED  LOGISTICS</div>
          </div>
          <div className='pc-clique-web mt-20 flex items-center' onClick={() => toJump('https://www.cndi.com')}>
            <div className='text-sm cursor-pointer'>中国南山集团官网</div>
            <img src={Next} alt="中国南山" />
          </div>
        </div>

        <div className="other grid grid-rows-2 grid-cols-2 gap-3">
          {
            showCompany()
          }
        </div>
      </div>
      <div className='w-full md:hidden lg:hidden mobile'>
        <div className='mobile-top'>
          <div className="mobile-logo flex items-center justify-center">
            <img src={Nanshan} alt="中国南山" />
            <div className='ml-2.5 text-sm'>旗下</div>
          </div>

          <div className="mobile-platform mt-6 text-center">
            <div className='text-xl cn'>综合物流平台</div>
            <div className='mt-2 text-xs en'>INTEGRATED  LOGISTICS</div>
          </div>
        </div>
        <div className='w-full mt-6 mobile-other'>
          {
            showCompany()
          }
        </div>

        <div className='mobile-web mt-8 flex items-center justify-center' onClick={() => toJump('https://www.cndi.com')}>
          <div className='text-sm'>中国南山集团官网</div>
          <img src={Next} alt="中国南山" />
        </div>
      </div>

    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #F4F4F7;
.pc{
    width: 1200px;
    margin: 0 auto;
    .pc-clique{
      background: url(${Watermark});
        background-repeat: no-repeat;
        background-size: 240px 240px;
        background-position: right bottom;
        .pc-clique-logo{
        img{
            width: 212px;
            height: 48px;
        }
        width: 324px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #43464F;
    }
    .pc-clique-platform{  
        font-family: PingFang SC;
        .cn{
            font-weight: 500;
            color: #43464F;
        }
        .en{
            font-weight: 400;
            color: #8E9095;
        }
    }
    .pc-clique-web{
        font-family: PingFang SC;
        font-weight: 400;
        color: #0169CB;
        img{
            width: 16px;
            height: 16px;
        }
    }
    .other{
        width: 632px;
    }
    }
}

.other-item{
    height: 132px;
    background: #ffff;
}

.other-item-left{
    padding: 24px 0 24px 20px;
}

.other-item-left-title{
    font-family: PingFang SC;
    font-weight: 600;
    color: #43464F;
}

.other-item-left-link{
    font-family: PingFang SC;
    font-weight: 400;
    color: #0169CB;
    img{
      width: 16px;
      height: 16px;
    }
}

.other-item-right{
    background-size: 100% 100% !important;
}

.mobile{
    padding: 80px 16px 12px;
    background: #F4F4F7;
    .mobile-top{
      padding: 16px;
      background: url(${Watermark});
        background-repeat: no-repeat;
        background-size: 114px 114px;
        background-position: center;
      .mobile-logo{
        img{
            width: 106px;
            height: 24px;
        }
        font-family: PingFang SC;
        font-weight: 400;
        color: #43464F;
    }
    .mobile-platform{  
        font-family: PingFang SC;
        .cn{
            font-weight: 500;
            color: #43464F;
        }
        .en{
            font-weight: 400;
            color: #8E9095;
        }
    }
    }

    .other-item{
        margin-bottom: 12px;
        background: #fff;
    }
    .mobile-web{
        font-family: PingFang SC;
        font-weight: 400;
        color: #0169CB;
        img{
            width: 16px;
            height: 16px;
        }
    }
    .up{
        font-family: PingFang SC;
        font-weight: 400;
        color: #8E9095;
        img{
            width: 24px;
            height: 24px;
        }
    }
}

 
`
