import React, { useState } from 'react'
import logo from '@/assets/logo_text.png'
import close from '@/assets/close.png'
import CloseIcon from '@/assets/retract_gray.png'
import OpenIcon from '@/assets/open_gray.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { isValidHttpUrl } from '@/utils/tool'
import { HeaderNavMenu, MenuInfo } from '@/consts/menu'

interface NavProps {
  visible: boolean
  onChange: (val: boolean) => void
}
export const Nav = ({ visible, onChange }: NavProps) => {
  const menuList = HeaderNavMenu
  const [list, setList] = useState(menuList)
  const showItem = (item: MenuInfo, index: number) => {
    if (item.children && item.children.length) {
      const menuList = [...list]
      menuList.forEach((vItem, vIdx) => {
        vIdx === index && (vItem.show = !vItem.show)
      })
      setList(menuList)
    } else {
      onNavigate(item.path)
    }
  }
  const location = useLocation()

  const onToggle = () => {
    onChange(!visible)
  }

  const navigate = useNavigate()
  const onNavigate = (url?: string) => {
    if (!url) return
    onChange(false)
    if (isValidHttpUrl(url)) {
      window.open(url)
    } else {
      navigate(url)
    }
  }

  return <div className={`${!visible && 'hidden'} fixed top-0 left-0 right-0 h-screen bg-white z-10`}>
    <div className='px-4 py-3 flex justify-between'>
      <img className='w-[92px] h-6' src={logo}></img>
      <img className='w-6 h-6' src={close} onClick={onToggle}></img>
    </div>
    <ul>
      {
        list.map((item, index) => <div key={item.title} className='w-full'>
          <div className={`flex justify-between cursor-pointer border-b border-borderOne font-medium mx-4 py-3 ${location.pathname === item.path ? 'text-primary' : 'text-textSecondary '}`} onClick={() => showItem(item, index)}>
            {item.title}
            {item.children && item.children.length ? <img className='w-6 h-6' src={item.show ? CloseIcon : OpenIcon} /> : ''}
          </div>
          {
            item.children && <ul className={`pl-4 transition-all duration-1000 ease-out overflow-hidden ${item.show ? 'h-' + (12 * item?.children.length) : 'h-0 opacity-0'}`}>
              {item.children.map(subItem =>
                <div key={subItem.title} className='py-3 pl-4  text-textSecondary cursor-pointer' onClick={() => { onNavigate(subItem.path) }}>
                  {subItem.title}
                </div>)}
            </ul>
          }
        </div>)
      }
    </ul>
  </div>
}
