import React from 'react'
import { getMenuRoutes } from './utils/menu'
import { Routes, Route } from 'react-router-dom'
import { Menus } from './consts/menu'
import { isMobile, isIOS } from 'react-device-detect'
import { Main } from './Main'

function App() {
  if (isMobile) {
    let dpr = 1
    if (isIOS) {
      dpr = Math.min(Math.round(window.devicePixelRatio), 2)
    }
    document.documentElement.className = 'g-dpr-' + dpr
  }
  return (
    <Routes>
      <Route element={
        <Main></Main>
      }>
        {getMenuRoutes(Menus)}
      </Route>
    </Routes>
  )
}

export default App
