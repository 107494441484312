import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import JindanPc from '@/assets/home/jindan-pc.png'
import RongziPc from '@/assets/home/rongzi-pc.jpg'
import YunpiaoPc from '@/assets/home/yunpiao-pc.jpg'
import JindanMobile from '@/assets/home/jindan-mobile.png'
import RongziMobile from '@/assets/home/rongzi-mobile.png'
import YunpiaoMobile from '@/assets/home/yunpiao-mobile.png'
import { ProductTabs } from './ProductTabs'

const Tabs = [
  {
    pcBg: JindanPc,
    mobileBg: JindanMobile,
    title: '金单产品',
    explain: '核心企业通过电子签名方式签发的，向供应商承诺到期支付货款的易流转、可拆分、秒融资、可追溯的电子债权凭证。',
    trait: [
      {
        summary: '易流转',
        text: '金单可由一级供应商流转至上游 2~N 级供应商，流转过程全线上，不占用银行授信，免除任何费用。'
      },
      {
        summary: '可拆分',
        text: '金单到期前，供应商可根据需要灵活拆分，拆分后的金单不影响正常使用或兑付，拆分不收取任何手续费。'
      },
      {
        summary: '秒融资',
        text: '融资流程全线上操作，免纸质申请资料，联合多家大型银行，渠道多，利率低，随时贴现。'
      },
      {
        summary: '可追溯',
        text: '绝大部分法律文件以电子数据形式保存并流转，金单在线生成流转轨迹和交易凭证，交易过程全程可追溯。'
      }
    ]
  },
  {
    pcBg: YunpiaoPc,
    mobileBg: YunpiaoMobile,
    title: '运票产品',
    explain: '核心企业在宝湾一路通系统签发的，且在指定日期支付确定金额运费给其承运人的应收账款债权电子凭证。承运人持有运票，可持有、可融资。',
    trait: [
      {
        summary: '高效闭环',
        text: '运票是与物流场景高度结合的金融产品，适配小而散的运输物流支付场景，实现运费支付、融资闭环操作。 '
      },
      {
        summary: '快捷方便',
        text: '金融系统与业务系统直连，用户可以在网货平台上办理物流业务和金融业务，享受产融无缝连接。'
      },
      {
        summary: '适用性强',
        text: '运票为场景里的不同参与方设计了匹配的使用方案，支持司机模式、运营商模式和经纪人模式，使用场景丰富。'
      },
      {
        summary: '安全可靠',
        text: '运票具有标准化、电子化特征，相比物流行业惯用的账期支付，可靠性高、易追踪、易操作、风险低。'
      }
    ]
  },
  {
    pcBg: RongziPc,
    mobileBg: RongziMobile,
    title: '融资租赁',
    explain: '出租人（资金方）根据承租人（融资人）对租赁物件的特定要求和对供货人的选择，出资向供货人购买租赁物件，并租给承租人使用，承租人则分期向出租人支付租金。',
    trait: [
      {
        summary: '时间长',
        text: '申请融资租赁后，承租人可享有资金 3 年左右，时间高于银行贷款。 '
      },
      {
        summary: '分期还',
        text: '承租人可根据自身的具体情况选择不同时间进行分期还款，减少还款压力，方便灵活。'
      },
      {
        summary: '门槛低',
        text: '融资租赁申请条件低于银行贷款，适合生产和加工的中小型企业。'
      }
    ]
  }
]

export const Product = () => {
  const [active, setActive] = useState(0)
  const [showAnimate, setShowAnimate] = useState<boolean>(false)
  useEffect(() => {
    const handleScroll = () => {
      // 获取元素相对于窗口的位置
      const element = document.getElementById('pcNews')
      if (!element) {
        return
      }
      const elementRect = element.getBoundingClientRect()
      const elementTop = elementRect.top + window.scrollY
      const elementBottom = elementRect.bottom + window.scrollY

      // 判断元素是否需要添加动效
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const windowHeight = window.innerHeight
      if (elementTop < scrollTop + windowHeight && elementBottom > scrollTop) {
        setShowAnimate(true)
      } else {
        setShowAnimate(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <Wrapper>
      <Pcwrapper className='sm:hidden relative'>
        <div className='w-full h-full absolute left-0 top-0 z-10 continer' style={{ background: `url(${Tabs[active].pcBg})` }}>
          {
            Tabs.map((item, index) =>
              <div key={`slide-${index}`} className='slide absolute h-full w-[1200px] top-0 left-2/4 translate-x-[-50%] pt-16 z-[1000]'>
                <div className={`slide-continer ${active === index ? 'active' : 'flyOut'}`}>
                  <div className='text-[32px] font-semibold sm:text-xl'>{item.title}</div>
                  <div className='w-[32px] h-[4px] sm:w-4 sm:h-[2px] mt-2 bg-white relative after:content after:block after:absolute after:w-0 after:h-0 after:-right-[4px] sm:after:-right-[2px] after:border-b-[4px] sm:after:border-b-[2px] after:border-b-white after:border-r-[4px] sm:after:border-r-[2px] after:border-r-transparent after:border-solid'></div>
                  <div className='explain mt-4 text-base w-[520px]'>{item.explain}</div>
                  <div className='trait mt-8 w-[520px]'>
                    {
                      item.trait.map((k, index) => (
                        <div className='trait-item mt-4' key={`trait-${index}`}>
                          <div className='trait-item-title text-lg'>{k.summary}</div>
                          <div className='trait-item-text text-base mt-2'>{k.text}</div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            )
          }
        </div>
        <div className={`w-[1200px] absolute left-2/4 tabs z-20 ${showAnimate ? 'flowIn' : 'bottom-14'}`}>
          <ProductTabs current={active} change={setActive} />
        </div>
      </Pcwrapper>
      <Mobilewrapper className='md:hidden lg:hidden relative'>
        {
          Tabs.map((item, index) =>
            <div key={`slide-${index}`} className={`slide w-full ${active === index ? 'show' : 'hide'}`} style={{ background: `url(${Tabs[active].mobileBg})` }}>
              <div className='w-full h-full wrap'>
                <div className="title relative text-2xl">{item.title}</div>
                <div className='explain mt-6 text-sm'>{item.explain}</div>
                <div className='trait mt-6'>
                  {
                    item.trait.map((k, index) => (
                      <div className='trait-item mt-4' key={`trait-${index}`}>
                        <div className='trait-item-title text-sm'>{k.summary}</div>
                        <div className='trait-item-text text-sm mt-2'>{k.text}</div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          )
        }
        <div className='w-full absolute bottom-16 tabs'>
          <ProductTabs current={active} change={setActive} />
        </div>
      </Mobilewrapper>

    </Wrapper>
  )
}


const Wrapper = styled.div`

  .c-mobile-center{
    width: 343px;
  }
`

const Pcwrapper = styled.div`
    @keyframes tabsFlyIn {
      0% {
        bottom: 0;
        opacity: 0;
      }

      100% {
        bottom: 56px;
        opacity: 1;
      }
    }

    @keyframes slideIn {
      0% {
        transform: translateX(-5%);
        opacity: 0;
      }

      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }
    @keyframes slideOUt {
      0% {
        transform: translateX(0);
        opacity: 1;
      }

      100% {
        transform: translateX(-5%);
        opacity: 0;
      }
    }
    height: 100vh;
    .continer{
       background-size: cover !important;
       transition: all 1s ease-in-out;
       .active{
        animation: slideIn 2s forwards;
       }
       .flyOut{
        animation: slideOUt 2s forwards;
       }
       .slide{
        color: #fff;
       }
       &::after{
            position: absolute;
            left: 0;
            top: 0;
            display: inline-block;
            content: " ";
            width: 50%;
            z-index: 1;
            height: 100vh;
            background: rgba(4, 8, 20,0.3);
        }
    }
   .tabs{
     transform: translateX(-50%);
   }
   .flowIn{
      animation: tabsFlyIn 2s forwards;
    }
`
const Mobilewrapper = styled.div`
    .hide{
        display: none !important;
    }
    .show{
        display: block;
    }
    .slide{ 
        height: 680px;
        background-size: 100% 100% !important;
        color: #fff;
        .wrap{  
        background: rgba(4,8,20,0.4); 
        padding: 48px 16px 0;
        &>.title::after{
            content: " ";
            display: inline-block;
            width: 24px;
            height: 2px;
            background: #FFFFFF;
            position: absolute;
            bottom: -8px;
            left: 2px;
        }
        }
    }
    .tabs{
        padding: 0 16px;
    }
`
