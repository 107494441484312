/**
 * 校验是否为合法网址
 */
export const isValidHttpUrl = (string: string) => {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

/**
 * 滚动到页面指定位置
 */
export const scrollToAnchor = (name: string) => {
  const anchorElement = document.getElementById(name)
  anchorElement && anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
}

/**
 * 节流
 */
export const throttle = (func: Function, delay: number, immediate:boolean): (...args: any[]) => void => {
  let timer: ReturnType<typeof setTimeout> | null = null
  let lastExecTime = 0

  return function(this: any, ...args: any[]) {

    if (immediate) {
      const currentExecTime = Date.now()
      if (currentExecTime - lastExecTime >= delay) {
        func.apply(this, args)
        lastExecTime = currentExecTime
      }
    } else {
      if (!timer) {
        timer = setTimeout(() => {
          func.apply(this, args)
          lastExecTime = Date.now()
          timer = null
        }, delay)
      }
    }
  }
}
