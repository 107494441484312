import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import AboutUs from '@/assets/about/banner.png'
import AboutUsMobile from '@/assets/about/banner_mobile.png'
import { Tab } from '@/components/Tab'
import { Introduce } from './components/Introduce'
import { Culture } from './components/Culture'
import { Team } from './components/Team'
import { useLocation } from 'react-router-dom'
import { Contact } from './components/Contact'
// import { isMobile } from 'react-device-detect'
import { Banner } from '@/components/Banner'

const tabs = [{
  title: '公司介绍',
  path: 'introduce'
},
{
  title: '企业文化',
  path: 'culture'
},
{
  title: '团队建设',
  path: 'team'
},
{
  title: '联系我们',
  path: 'contact'
}
]

export const About = () => {
  const [active, setActive] = useState(0)

  const scrollToAnchor = (id:string) => {
    const targetElement = document.getElementById(id)
    if (targetElement) {
      const { top } = targetElement.getBoundingClientRect()
      window.scrollTo({
        top: window.scrollY  + top,
        behavior: 'smooth' // 平滑滚动
      })
    }
  }

  const changeTabs = (active:number) => {
    setActive(active)
    scrollToAnchor(tabs[active].path)
  }

  // 页面滚动
  const location = useLocation()
  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const id = query.get('id')
    if (id) {
      scrollToAnchor(id)
      const active = tabs.findIndex(v => v.path === id)
      setActive(active)
    }
  }, [location.search])


  return (
    <Wrapper>
      <Banner src={AboutUs} mobileSrc={AboutUsMobile} title='关于我们'></Banner>
      <Tab list={tabs} active={active} onChange={changeTabs}></Tab>
      <div id='introduce'>
        <Introduce />
      </div>
      <div id='culture'>
        <Culture />
      </div>
      <div id='team'>
        <Team />
      </div>
      <div id='contact'>
        <Contact />
      </div>
    </Wrapper>
  )

}


const Wrapper = styled.div`
  padding: 0;

`
