import React, { useState } from 'react'
import OpenIcon from '@/assets/open.png'
import CloseIcon from '@/assets/retract.png'
import { isValidHttpUrl } from '@/utils/tool'
import { useLocation, useNavigate } from 'react-router-dom'
import { FooterMenu } from '@/consts/menu'
import Up from '@/assets/up.png'
import QrCode from '@/assets/qr.png'
import Beian from '@/assets/beian.png'

export const Footer = () => {
  const [menuList, setMenuList] = useState(FooterMenu)
  const showItem = (index: number) => {
    const list = [...menuList]
    list.forEach((vItem, vIdx) => {
      vIdx === index && (vItem.show = !vItem.show)
    })
    setMenuList(list)
  }

  const navigate = useNavigate()
  const onNavigate = (url: string) => {
    if (!url) return
    if (isValidHttpUrl(url)) {
      window.open(url)
    } else {
      navigate(url)
    }
  }

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  // 部分页面不展示回到顶部
  const location = useLocation()
  const showPageList = [
    '/',
    '/business',
    '/news',
    '/news/list'
  ]
  const showBack = showPageList.includes(location.pathname)

  return <div className='relative bg-primary pt-14 pb-8 sm:pt-0'>
    <div className='sm:w-full sm:text-sm md:w-11/12 lg:w-[1200px] mx-auto flex sm:flex-wrap justify-between'>
      <div className='sm:hidden flex'>
        {
          menuList.map(item => <div key={item.title} className='w-36 mr-6'>
            <div className='text-white mb-6 cursor-pointer' onClick={() => { onNavigate(item.path) }}>{item.title}</div>
            {
              item.children && <ul>{item.children.map(subItem => <div key={subItem.title} className='text-white/[.65] cursor-pointer hover:underline hover:text-white mt-2' onClick={() => { onNavigate(subItem.path) }}>{subItem.title}</div>)}
              </ul>
            }
          </div>)
        }
      </div>
      {/* 小屏展示 */}
      {showBack && <div className='md:hidden lg:hidden w-full mb-12 py-3 bg-[#F4F4F7] flex items-center justify-center' onClick={toTop}>
        <div className='text-sm mr-2 text-textTertiary'>回到顶部</div>
        <img className='w-6 h-6' src={Up} alt="宝湾资本" />
      </div>}
      <div className='md:hidden lg:hidden w-full mb-12 sm:px-4'>
        {
          menuList.map((item, index) => <div key={item.title} className='w-full '>
            <div className='flex justify-between cursor-pointer text-white border-b border-white/[.1] py-3' onClick={() => showItem(index)}>
              {item.title}
              <img className='w-6 h-6' src={item.show ? CloseIcon : OpenIcon} />
            </div>
            {
              item.children && <ul className={`transition-all duration-1000 ease-out overflow-hidden ${item.show ? 'h-' + (12 * item.children.length) : 'h-0 opacity-0'}`}>
                {item.children.map(subItem =>
                  <div key={subItem.title} className='py-3 pl-4 text-white/[.65] cursor-pointer hover:underline hover:text-white' onClick={() => { onNavigate(subItem.path) }}>
                    {subItem.title}
                  </div>)}
              </ul>
            }
          </div>)
        }
      </div>
      <div className='sm:mb-[48px] lg:mb-[88px] md:mb-[88px] sm:px-4'>
        <div className='flex mb-8'>
          <img className='w-[88px] h-[88px] mr-4' src={QrCode} alt="" />
        </div>
        <div className='text-white/[.65] mb-2'>联系电话：0755-21622822</div>
        <div className='text-white/[.65]'>公司地址：深圳市南山区赤湾六8号赤湾总部大厦28楼</div>
      </div>
      <div className='w-full flex items-center text-white sm:px-4 lg:absolute md:absolute bottom-8 pr-4'>宝湾资本管理有限公司，<a href='https://beian.miit.gov.cn/#/Integrated/index' target="_blank" rel="noreferrer">津ICP备2023007290号-1</a>，<img src={Beian} className='w-[15px] h-[15px] mr-1' /><a href= "https://beian.mps.gov.cn/#/query/webSearch?code=44030002001717" rel="noreferrer" target="_blank">粤公网安备44030002001717</a>
      </div>
    </div>
  </div>
}
